import React from 'react'
import { hydrate, render } from "react-dom";
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'

import Client from 'shopify-buy'

import { Component } from 'react';

import { Helmet, HelmetProvider } from 'react-helmet-async'

const shopifyToken = process.env.REACT_APP_SHOPIFY_TOKEN

const shopifyClient = Client.buildClient({
  storefrontAccessToken: shopifyToken,
  domain: 'sight-industries.myshopify.com'
})

// const apiEndpoint = 'https://tienda.cdn.prismic.io/api/v2'
// const accessToken = process.env.REACT_APP_PRISMIC_TOKEN

// const prismicClient = Prismic.client(apiEndpoint, { accessToken })


class Index extends Component {

  render() {
    return (
      <HelmetProvider>
        <Helmet>
          <meta property='og:title' content='Tienda - Design Store' />
          <meta property='og:description' content='A modern design store from Sight.' />
          <meta property='og:image' content='https://tienda.nyc/icon.png' />
          <meta property='og:url' content='https://tienda.nyc/' />
        </Helmet>
        <App client={shopifyClient}
          // prismicClient={prismicClient}
        />
      </HelmetProvider>
    )
  }
}

const rootElement = document.getElementById('root')
if (rootElement.hasChildNodes()) {
  hydrate(<Index client={shopifyClient} />, rootElement)
} else {
  render(<Index client={shopifyClient} />, rootElement)
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
