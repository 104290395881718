import React, { Component } from 'react';
import { Carousel } from 'react-responsive-carousel';

import { carouselAssets } from '../App'

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (
            <div className='footer'>
                <Carousel className={'footer-carousel'} onChange={(index) => {this.setState({slide: index}); }} showThumbs={false} swipeable={true} autoPlay={true} showIndicators={false} showStatus={false} showArrows={true} infiniteLoop={true} interval={8000} transitionTime={400} 
                    renderArrowNext={(onClickHandler, hasNext, label) => (
                        <button className="carousel-button" onClick={onClickHandler} title={label}>
                            ▶
                        </button>
                    )}
                    renderArrowPrev={(onClickHandler, hasNext, label) => (
                        <button className="carousel-button" onClick={onClickHandler} title={label}>
                            ◀
                        </button>
                    )}>
                  {carouselAssets.map((asset) => {
                    return (
                        <a href={asset.url}>
                            <div className='line'><h3>{asset.title}</h3><span className='date'>{asset.date}</span></div>
                            <span>{asset.text}</span>
                        </a>
                        )
                  })}
                </Carousel>
                <div className='footer-payments'>
                    <img src='/img/payment.svg' alt='Payment methods'/>
                </div>
                <div className='footer-copyright'>
                    <div>
                        <span>Ⓤ <b>sight</b> 2021</span>
                        <span>Designed in NYC</span>
                    </div>
                </div>
            </div>
        );
    }
}

export default Footer;
