import React, { Component, Fragment } from 'react';
import LineItem from './LineItem';

class Cart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isCartOpen: props.isCartOpen
    }
    this.openCheckout = this.openCheckout.bind(this);


  }

  openCheckout() {
    window.open(this.props.checkout.webUrl);
  }



  render() {
    let line_items = this.props.checkout.lineItems.map((line_item) => {
      return (
        <LineItem
          updateQuantityInCart={this.props.updateQuantityInCart}
          removeLineItemInCart={this.props.removeLineItemInCart}
          key={line_item.id.toString()}
          line_item={line_item}
        />
      );
    });

    return (
      <Fragment>
        <div className='App__view-cart-wrapper'>
          <button className='App__view-cart' onClick={() => { this.props.handleCartOpen() }}>
            <img src='/img/cart.png' alt="Cart"/>
            { line_items.length ? (<span className='count'>{line_items.length}</span>) : ''}
          </button>
        </div>

        <div className={`Cart ${this.props.isCartOpen ? 'Cart--open' : ''}`}>
          <header className="Cart__header">
            <h3>Your Cart</h3>
            <button
              onClick={() => { this.props.handleCartClose() }}
              className="Cart__close">
              ×
          </button>
          </header>
          <ul className="Cart__line-items">
            {line_items}
          </ul>
          <footer className="Cart__footer">
            {/* <div className="Cart-info clearfix">
              <div className="Cart-info__total Cart-info__small">Subtotal</div>
              <div className="Cart-info__pricing">
                <span className="pricing">$ {this.props.checkout.subtotalPrice}</span>
              </div>
            </div>
            <br />
            <div className="Cart-info clearfix">
              <div className="Cart-info__total Cart-info__small">Taxes</div>
              <div className="Cart-info__pricing">
                <span className="pricing">$ {this.props.checkout.totalTax}</span>
              </div>
            </div>
            <br /> */}
            <div className="Cart-info clearfix">
              <div className="Cart-info__total Cart-info__small">Total</div>
              <div className="Cart-info__pricing">
                <span className="pricing">$ {this.props.checkout.totalPrice}</span>
              </div>
            </div>
            <button className="Cart__checkout button tienda-button" onClick={this.openCheckout}>Checkout</button>
            <div className="Cart-info full">
              <div className="Cart-info__additional Cart-info__small">You can apply discounts at checkout.</div>
            </div>
          </footer>
        </div>
      </Fragment>
    )
  }
}

export default Cart;
